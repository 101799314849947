import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import { registerLicense } from "@syncfusion/ej2/base";
import Loader from "./shade/Loaders/Loaders"
import PrivateRoute from './utils/PrivateRoute';

const App = React.lazy(() => import("../src/shade/layouts/App"));
const Auth = React.lazy(() => import("./views/Authenticate/Constainers/auth"));
const Login = React.lazy(() => import("./views/Authenticate/Components/Login"));
const Home = React.lazy(() => import("./views/Authenticate/Components/Home"));
const ResetPassword = React.lazy(() => import("./views/Authenticate/Components/ResetPassword"));
const ForgotPassword = React.lazy(() => import("./views/Authenticate/Components/ForgotPassword"));
const UserList = React.lazy(() => import("./views/Users/Containers/UserList"));

const EmployeesList = React.lazy(() => import("./views/Employees/Containers/EmployeesList"));
const StatusList = React.lazy(() => import("./views/Status/Containers/StatusList"));//
const AbsenceTypeList = React.lazy(() => import("./views/AbsenceType/Containers/AbsenceTypeList"));
const ConstancyTypeList = React.lazy(() => import("./views/ConstancyType/Containers/ContancyTypeList"));
const PermissionRequestList = React.lazy(() => import("./views/PermissionRequest/Containers/PermissionRequestList"));
const PermissionRecord = React.lazy(() => import("./views/PermissionRequest/Components/PermissionRecord"));
const PermissionRequestManag = React.lazy(() => import("./views/PermissionRequest/Containers/PermissionRequestManag"));
const PermissionRequestOutputs = React.lazy(() => import("./views/PermissionRequest/Containers/PermissionRequestOutputs"));
const PermissionRequestUploadLynx = React.lazy(() => import("./views/PermissionRequest/Containers/PermissionRequestUploadLynx"));
const PlanillaAusenciaLynx = React.lazy(() => import("./views/PermissionRequest/Containers/PlanillaAusenciaLynx"));
const ConstancyList = React.lazy(() => import("./views/Constancy/Containers/ConstancyList"));
const ConstancyRecord = React.lazy(() => import("./views/Constancy/Components/ConstancyRecord"));
const ConstancyManagList = React.lazy(() => import("./views/Constancy/Containers/ConstancyManagList"));
const ConstancyDocumentEditor = React.lazy(() => import("./views/Constancy/Components/ConstancyDocumentEditor"));
const ConstancyDocumentEditor1 = React.lazy(() => import("./views/Constancy/Components/ConstancyDocumentEditor1"));
const Roles = React.lazy(() => import("./views/Roles/Containers/RolesList"));
const Permission = React.lazy(() => import("./views/PermissionSecurity/Containers/PermissionList"));
const PermissionRol = React.lazy(() => import("./views/Roles/Components/PermissonRoles"));
const UserRoles = React.lazy(() => import("./views/Users/Components/UserRoles"));
const AccessDenied = React.lazy(() => import("./views/GlobalComponents/AccessDenied"));
const HolidaysList = React.lazy(() => import("./views/Holidays/Containers/HolidaysList"));
const RecordUserByEmploye = React.lazy(() => import("./views/Users/Components/RecordUserByEmploye"));
const SettingsList = React.lazy(() => import("./views/Settings/Containers/SettingsList"));
const SubirDeducCafetLynx = React.lazy(() => import("./views/Cafeteria/Containers/SubirDeducCafetLynx"));
const ConsultarCafeteria = React.lazy(() => import("./views/Cafeteria/Containers/ConsultarCafeteria"));

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
registerLicense("Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXldcHVWR2ldWER0XkA=");
root.render(
    <React.Fragment>
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
                        <Route index element={<Login />} />
                        <Route path={`${process.env.PUBLIC_URL}/AccessDenied`} element={<AccessDenied />} />
                        <Route path={`${process.env.PUBLIC_URL}/RecordUserByEmploye`} element={<RecordUserByEmploye />} />
                        <Route path={`${process.env.PUBLIC_URL}/ReestablecerCredenciales/:user`} element={<ResetPassword />} />
                        <Route path={`${process.env.PUBLIC_URL}/RecuperarCredenciales`} element={<ForgotPassword />} />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                        <Route path={`${process.env.PUBLIC_URL}/Home`} element={<Home />} />
                        <Route path={`${process.env.PUBLIC_URL}/Usuarios`} element={<PrivateRoute children={<UserList />} path={`/Usuarios`}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/EmployeesList`} element={<PrivateRoute children={<EmployeesList />} path={'/EmployeesList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/StatusList`} element={<PrivateRoute children={<StatusList />} path={'/StatusList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/AbsenceTypeList`} element={<PrivateRoute children={<AbsenceTypeList />} path={'/AbsenceTypeList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/ConstancyTypeList`} element={<PrivateRoute children={<ConstancyTypeList />} path={'/ConstancyTypeList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/PermissionRequestList`} element={<PrivateRoute children={<PermissionRequestList />} path={'/PermissionRequestList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/RegistrarPermiso`} element={<PrivateRoute children={<PermissionRecord />} path={'/RegistrarPermiso'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/EditarPermiso`} element={<PrivateRoute children={<PermissionRecord />} path={'/RegistrarPermiso'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/GestionPermiso`} element={<PrivateRoute children={<PermissionRequestManag />} path={'/GestionPermiso'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/SubirPermisoLynx`} element={<PrivateRoute children={<PermissionRequestUploadLynx />} path={'/SubirPermisoLynx'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/PlanillaAusenciaLynx`} element={<PrivateRoute children={<PlanillaAusenciaLynx />} path={'/PlanillaAusenciaLynx'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/PermisoSalida`} element={<PrivateRoute children={<PermissionRequestOutputs />} path={'/PermisoSalida'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/ListaConstancia`} element={<PrivateRoute children={<ConstancyList />} path={'/ListaConstancia'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/SolicitarConstancia`} element={<PrivateRoute children={<ConstancyRecord />} path={'/SolicitarConstancia'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/EditarConstancia`} element={<PrivateRoute children={<ConstancyRecord />} path={'/SolicitarConstancia'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/GestionConstancia`} element={<PrivateRoute children={<ConstancyManagList />} path={'/GestionConstancia'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/ConstDocEditor`} element={<PrivateRoute children={<ConstancyDocumentEditor />} path={'/ConstDocEditor'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/ConstDocEditor1`} element={<PrivateRoute children={<ConstancyDocumentEditor1 />} path={'/ConstDocEditor1'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/HolidaysList`} element={<PrivateRoute children={<HolidaysList />} path={'/HolidaysList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/SettingsList`} element={<PrivateRoute children={<SettingsList />} path={'/SettingsList'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/SubirDeducCafetLynx`} element={<PrivateRoute children={<SubirDeducCafetLynx />} path={'/SubirDeducCafetLynx'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/ConsultarCafeteria`} element={<PrivateRoute children={<ConsultarCafeteria />} path={'/ConsultarCafeteria'}></PrivateRoute>} />

                        <Route path={`${process.env.PUBLIC_URL}/Roles`} element={<PrivateRoute children={<Roles />} path={'/Roles'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/Permisos`} element={<PrivateRoute children={<Permission />} path={'/Permisos'}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/PermissionRol/:rolId`} element={<PrivateRoute children={<PermissionRol />} path={`/PermissionRol/:rolId`}></PrivateRoute>} />
                        <Route path={`${process.env.PUBLIC_URL}/UserRoles/:userId`} element={<PrivateRoute children={<UserRoles />} path={`/UserRoles/:userId`}></PrivateRoute>} />

                    </Route>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    </React.Fragment>
);

reportWebVitals();
