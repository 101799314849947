import { combineReducers } from "redux";
import { HRPlatform } from "./reducer";


const rootred = combineReducers({
    HRPlatform
});


export default rootred
