const INIT_STATE: any = {
    absenceTypeSelected: null,
    absencesTypes: [],
    permissionRequestSelected: null,
    editPermissionRequestSelected: null,
    datoEmpleado: null,
    statusPermission: [],
    statusAll: null,
    constancySelected: null,
    constancyDayNew: null,
    settingsAll: null,
    setDataConstancy: null,
    token: null,
    tokenEncrypt: null,
    AccessByUser: null,
    setVacationEmployee: null,
    user: null,
    allCompany: null,
};


export const HRPlatform = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case "ABSENCETYPESELECTED":
            return {
                ...state,
                absenceTypeSelected: action.payload
            }
        case "ABSENCETYPELIST":
            return {
                ...state,
                absencesTypes: action.payload
            }
        case "EDITPERMISSIONREQUESTSELECTED":
            return {
                ...state,
                editPermissionRequestSelected: action.payload
            }
        case "PERMISSIONREQUESTSELECTED":
            return {
                ...state,
                permissionRequestSelected: action.payload
            }
        case "DATOSEMPLEADOS":
            return {
                ...state,
                datoEmpleado: action.payload
            }
        case "STATUSPERMISSION":
            return {
                ...state,
                statusPermission: action.payload
            }
        case "STATUSALL":
            return {
                ...state,
                statusAll: action.payload
            }
        case "CONSTANCYSELECTED":
            return {
                ...state,
                constancySelected: action.payload
            }
        case "CONSTANCYDAYNEW":
            return {
                ...state,
                constancyDayNew: action.payload
            }
        case "SETTINGSALL":
            return {
                ...state,
                settingsAll: action.payload
            }
        case "SETDATACONSTANCY":
            return {
                ...state,
                setDataConstancy: action.payload
            }
        case "TOKEN":
            return {
                ...state,
                token: action.payload
            }
        case "TOKENENCRYPT":
            return {
                ...state,
                tokenEncrypt: action.payload
            }
        case "ACCESSBYUSER":
            return {
                ...state,
                AccessByUser: action.payload
            }
        case "SETVACATIONEMPLOYEE":
            return {
                ...state,
                setVacationEmployee: action.payload
            }
        case "USER":
            return {
                ...state,
                user: action.payload
            }
        case "ALLCOMPANY":
            return {
                ...state,
                allCompany: action.payload
            }
        default:
            return state
    }
}
