import { compose, createStore } from "redux";
import rootred from "../reducers/main";
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

const persistConfig = {
    key: 'root',
    storage: localForage,
}


declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootred)
const store = createStore(
    persistedReducer,composeEnhancers()
);

export default store;
