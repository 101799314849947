import { valideAccessViews } from "./ValidateAccess";

const PrivateRoute = ({ children, path }) => {
  if (!valideAccessViews(path)) {
    window.location.href = `${process.env.PUBLIC_URL}/AccessDenied`;
  }
  return children;
};

// export default PrivateRoute
// import { useSelector } from "react-redux";
// import moment from "moment";
// import { useDispatch } from 'react-redux';
// import { setInitState } from "../redux/actions/action";

// const PrivateRoute = ({ children }) => {
//   const dispatch = useDispatch();
//   const userLogin = useSelector((state) => state.trackingSys.userLogin);
//     var token = localStorage.getItem('token')
//     let path = `${process.env.PUBLIC_URL}/Login`;
//     if (token === null || token === '') {
//       dispatch(setInitState());
//       window.location.href=path;
//     }
//     else {
//       if (userLogin.EndDate < moment().format()) {
//         dispatch(setInitState());
//         window.location.href=path;
//       }
//     }
//   return children
// };
export default PrivateRoute;
