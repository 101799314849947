import { IPermission } from "../interfaces/IPermission";
import store from "../redux/store/store";

export const valideAccessMenu = (menu: string) => {
    if (!store.getState().HRPlatform.AccessByUser) {
        window.location.href = `${process.env.PUBLIC_URL}/`;
    }
    const globalState: any = store.getState().HRPlatform.AccessByUser;
    for (let rol of globalState.rolesUser) {
        let validate = rol.permissionRoles.filter((x: IPermission) => x.menu.toUpperCase() === menu.toUpperCase())
        if (validate.length > 0) {
            return true
        }
    }
    return false
}

export const valideAccessViews = (route: string) => {
    const globalState: any = store.getState().HRPlatform.AccessByUser;
    for (let rol of globalState.rolesUser) {
        for (let permission of rol.permissionRoles) {
            
            if (permission.route.toUpperCase() === route.toUpperCase()) {
                return true;
            }
        }
    }
    return false;
}